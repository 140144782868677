body {
  width: 100%;
  margin: 0;
  background-image: url("./images/Piik-Pok-Advent-Calendar_BG3.png");
  background-position: left;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.App {
  text-align: center;
  min-width: 280;
}
.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

.flex-parent {
  padding-top: 25px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
}

.appFooter{
  width: 100%;
  position: fixed;
  display: block;
  text-align: center;
  font:10px;
  color:white;
  bottom: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  z-index: 10;
}



.appStaticInfocontainer {
  position: fixed;
  display: block;
  text-align: center;    
  width:auto;
  background-image: linear-gradient(rgba(77,101,120,0), rgba(37,55,69,0.9) 10%, rgba(37,55,69,0.9) 85%, rgba(77,101,120,0));     
  height:250px;
  width:325px;
  opacity: 1;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);        
}

.appStaticInfocontainer #title{
position: relative;
font-size: 22px;  
font-family: 'DM Sans', sans-serif;
font-weight: regular;
text-transform: none;
color: #ffffff;
margin-top:30px;
margin-left: 5px;
margin-right: 5px;
opacity: 1;  
}

.appStaticInfocontainer #body{
  position: relative;
  font-size: 16px;  
  font-family: 'DM Sans', sans-serif;
  font-weight: regular;
  text-transform: none;
  color: #ffffff;
  margin-top:15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  opacity: 1;  
}
