
.CountdownContainer {
    position: fixed;
    display: block;
    text-align: center;    
    width:auto;
    background-image: linear-gradient(rgba(77,101,120,0), rgba(37,55,69,0.9) 10%, rgba(37,55,69,0.9) 85%, rgba(77,101,120,0));     
    height:250px;
    width:325px;
    opacity: 1;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);        
}

.CountdownContainer #title{
  position: relative;
  font-size: 22px;  
  font-family: 'DM Sans', sans-serif;
  font-weight: regular;
  text-transform: none;
  color: #ffffff;
  margin-top:30px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: 1;  
}

.countdown{
    position: relative;
    display: block;    
    font-family: 'DM Sans', sans-serif;    
    text-transform: none;
    color: #ffffff;
    padding-bottom: 20px;
}

.countdown ul{    
  display: inline-block;
  list-style-type: none;
  padding: 0px;
}

.countdown ul li{    
  display: inline-block;
  font-size: 12px;  
  font-family: 'DM Sans', sans-serif;
  list-style-type: none ;
  padding: 1em;
  text-transform: none;
  text-align: center;
  color: #ffffff;
  background-color: none;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  width: 40px;
  height: 50px;
  opacity: 1;
}

.countdown ul li span{
  display: block;
  font-size: 32px;
  font-weight: bold;
  padding-top: 1px;
  opacity: 1;
}


.demo-video-content{
  position: relative;
  display: block;
  top: 0px;    
  width: 100%;    
  margin-top: 10px;
  text-align: center;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.styled {
  display: inline-block;
  border: none;    
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
  text-transform: none;
  border-radius: 0px;
  background-color: #f79826;
  box-shadow: 1px 8px 10px 5px rgba(0,0,0,0.2);
  width:50%;
  padding: 15px 15px;
}

.styled:hover {
  color: white;
  background-color: #8a0000;
  cursor: pointer;
}
